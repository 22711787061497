import React from "react";
import {Link} from 'gatsby';
//import logo from "../images/skillmeter_logo.png";
import bg_pattern from '../images/bg-pattern.png';
import Footer from "../components/Footer";
import Header from '../components/Header';
import { Helmet } from "react-helmet"
import heroImg from "../images/skillmeter-world4.png";
import clientUbi from '../images/ubisoft-logo.png';
import clientElevate from '../images/elevate-logo.svg';
import clientIntertech from '../images/intertech-logo.png';
import clientGtnexus from '../images/gtnexus-logo.jpeg';
import featurePoster from '../images/poster.png';
import featureReport from '../images/report.png';
import featureSecurity from '../images/security.png';
import featureCloud from '../images/www.png';
import featureSurvey from '../images/survey.png';
import featureClock from '../images/clock.png';

export default function Home ()  {
  const features = [
    {
      name: 'Available anywhere/anytime',
      description:
        'Tests are available 24/7 and candidates can take the assessments from anywhere in the world, with any browser.',
      icon: featureClock,
    },
    {
      name: 'Use your own tests',
      description:
      'Skillmeter allows you build your tests in any format: timed, scored and with a wide variety of question types.',
      icon: featureSurvey,
    },
    {
      name: 'Flexible branding',
      description:
        'Personalize your test center with your own logo and your own sub-domain name to keep the identity of your company.',
      icon: featurePoster,
    },
    {
      name: 'Fast results reporting',
      description:
        'You get tests results instantly by email and they are also archived in the system for later reference.',
      icon: featureReport,
    },
    {
      name: 'Secure',
      description:
        'We take privacy & security seriously. All information is transmitted over a secure and encrypted connection.',
      icon: featureSecurity,
    },
    {
      name: 'No IT required',
      description:
        "Skillmeter runs in the cloud so you don't have to worry about installing or upgrading your software. Every new feature we add is instantly available.",
      icon: featureCloud,
    }

  ]
  
  
return (
<>
  <Helmet>
    <meta charSet="utf-8" />
    <title>Online Skills Testing Platform | Skillmeter</title>
    <meta name="description" content="Skillmeter Testing Platform helps recruiters measure candidates skills through online tests." />
    <meta name="keywords" content="candidate testing, online assessment, online testing, skills assessment, recruitment testing, pre employment screening, assessment testing" />    
    <meta name="alexaVerifyID" content="BQDrmyoZ7ZwQk2uLq2TK1QsLqj0" />    
    <link rel="canonical" href="https://skillmeter.com" />
  </Helmet>


  <Header /> 

  {/* Hero section */}

  <div className="relative bg-gray-50">
    <div className="md:absolute md:bottom-0 md:right-0 overflow-hidden lg:inset-y-0 hidden md:block">
      <img className="w-auto h-full" src={bg_pattern} alt="" />
    </div>

    <section className="relative py-12 sm:py-16 lg:py-20 lg:pb-36">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="grid max-w-lg grid-cols-1 mx-auto lg:max-w-full lg:items-center lg:grid-cols-2 gap-y-12 lg:gap-x-8">
          <div>
            <div className="text-center lg:text-left">
              <h1 className="text-4xl font-bold leading-tight text-gray-900 sm:text-5xl sm:leading-tight lg:leading-tight lg:text-6xl ">
                Hire the right talent.
              </h1>
              <p className="mt-2 text-lg text-gray-600 sm:mt-8 font-inter">
              Skillmeter ensures you're hiring successfully by preselecting candidates based on their actual skills and knowledge. Save time, money and avoid stress.
              </p>

              <Link to='/pricing' className="inline-flex px-6 py-3 text-lg font-bold text-white transition-all duration-200 bg-green-600 rounded-lg focus:outline-none focus:bg-green-900  hover:bg-green-900">
                Start a free trial
              </Link>
            </div>

            <div className="flex items-center justify-center mt-10 space-x-6 lg:justify-start sm:space-x-8">
              <div className="flex items-center">
                <p className="text-3xl font-medium text-gray-900 sm:text-4xl ">
                  250K+
                </p>
                <p className="ml-3 text-sm text-gray-900 ">
                  Tests<br />taken
                </p>
              </div>

              <div className="hidden sm:block">
                <svg className="text-gray-400" width="16" height="39" viewBox="0 0 16 39" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <line x1="0.72265" y1="10.584" x2="15.7226" y2="0.583975" />
                  <line x1="0.72265" y1="17.584" x2="15.7226" y2="7.58398" />
                  <line x1="0.72265" y1="24.584" x2="15.7226" y2="14.584" />
                  <line x1="0.72265" y1="31.584" x2="15.7226" y2="21.584" />
                  <line x1="0.72265" y1="38.584" x2="15.7226" y2="28.584" />
                </svg>
              </div>

              <div className="flex items-center">
                <p className="text-3xl font-medium text-gray-900 sm:text-4xl ">
                  3M+
                </p>
                <p className="ml-3 text-sm text-gray-900 ">
                  Questions<br />Answered
                </p>
              </div>
            </div>
          </div>

          <div>
            {/*<img className="w-full" src="https://cdn.rareblocks.xyz/collection/clarity/images/hero/5.2/illustration.png" alt="" />*/}
            <img className="w-full" src={heroImg} alt="" />

          </div>
        </div>
      </div>
    </section>

  </div>


  {/* benefits at a glance */}
  <div className="bg-white">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 uppercase">Benefits at a glance</h2>
          <p className="mt-4 text-lg text-gray-500">
          On average, recruiters who use Skillmeter decide a job candidate's suitability in half the time. They save one hour per job candidate and often get compliments from clients about them.
          </p>
        </div>

        <div className="mt-20">
          <dl className="ml-20 mr-20 space-y-10 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10 md:space-y-0">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex h-12 w-12 items-center justify-center ">
                    <img src={feature.icon} className="h-12 w-12" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg font-medium leading-6 text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>

      </div>
    </div>
  {/* create tests that work */}
  <div className="bg-blue-50">
      <div className="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
        <div className="mx-auto max-w-3xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 uppercase">Create tests that work</h2>
          <p className="mt-4 text-lg text-gray-500">
          We provide you everything you need to create simple to sophisticated assessments in minutes.
          </p>
        </div>
        <dl className="mt-12 lg:ml-10 space-y-10 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8">
            <div className="relative">
              <dt className="flex">
               <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M0 128C0 92.7 28.7 64 64 64H320c35.3 0 64 28.7 64 64V384c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V128zM559.1 99.8c10.4 5.6 16.9 16.4 16.9 28.2V384c0 11.8-6.5 22.6-16.9 28.2s-23 5-32.9-1.6l-96-64L416 337.1V320 192 174.9l14.2-9.5 96-64c9.8-6.5 22.4-7.2 32.9-1.6z"/></svg>              
                <p className="ml-4 text-lg font-medium leading-6 text-gray-900"> Video recording</p>
              </dt>
            </div>

            <div className="relative">
              <dt className="flex">
                <svg className='h-6 w-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M64 64C28.7 64 0 92.7 0 128V384c0 35.3 28.7 64 64 64H512c35.3 0 64-28.7 64-64V128c0-35.3-28.7-64-64-64H64zm16 64h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16zM64 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V240zm16 80h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H80c-8.8 0-16-7.2-16-16V336c0-8.8 7.2-16 16-16zm80-176c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V144zm16 80h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V240c0-8.8 7.2-16 16-16zM160 336c0-8.8 7.2-16 16-16H400c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H176c-8.8 0-16-7.2-16-16V336zM272 128h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16zM256 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H272c-8.8 0-16-7.2-16-16V240zM368 128h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16zM352 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H368c-8.8 0-16-7.2-16-16V240zM464 128h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H464c-8.8 0-16-7.2-16-16V144c0-8.8 7.2-16 16-16zM448 240c0-8.8 7.2-16 16-16h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H464c-8.8 0-16-7.2-16-16V240zm16 80h32c8.8 0 16 7.2 16 16v32c0 8.8-7.2 16-16 16H464c-8.8 0-16-7.2-16-16V336c0-8.8 7.2-16 16-16z"/></svg>
                <p className="ml-4 text-lg font-medium leading-6 text-gray-900">Typing speed</p>
              </dt>
            </div>            

            <div className="relative">
              <dt className="flex">                
                <svg className='h-6 w-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 109.3V352c0 17.7-14.3 32-32 32s-32-14.3-32-32V109.3l-73.4 73.4c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l128-128c12.5-12.5 32.8-12.5 45.3 0l128 128c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L288 109.3zM64 352H192c0 35.3 28.7 64 64 64s64-28.7 64-64H448c35.3 0 64 28.7 64 64v32c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V416c0-35.3 28.7-64 64-64zM432 456c13.3 0 24-10.7 24-24s-10.7-24-24-24s-24 10.7-24 24s10.7 24 24 24z"/></svg>              
                <p className="ml-4 text-lg font-medium leading-6 text-gray-900">File upload</p>
              </dt>
            </div>            

            <div className="relative">
              <dt className="flex">
                <svg className='h-6 w-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M392.8 1.2c-17-4.9-34.7 5-39.6 22l-128 448c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l128-448c4.9-17-5-34.7-22-39.6zm80.6 120.1c-12.5 12.5-12.5 32.8 0 45.3L562.7 256l-89.4 89.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l112-112c12.5-12.5 12.5-32.8 0-45.3l-112-112c-12.5-12.5-32.8-12.5-45.3 0zm-306.7 0c-12.5-12.5-32.8-12.5-45.3 0l-112 112c-12.5 12.5-12.5 32.8 0 45.3l112 112c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256l89.4-89.4c12.5-12.5 12.5-32.8 0-45.3z"/></svg>
                <p className="ml-4 text-lg font-medium leading-6 text-gray-900">Programming</p>
              </dt>
            </div>            

            <div className="relative">
              <dt className="flex">
                <svg className='h-6 w-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M64 32C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96c0-35.3-28.7-64-64-64H64zM337 209L209 337c-9.4 9.4-24.6 9.4-33.9 0l-64-64c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l47 47L303 175c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9z"/></svg>                
                <p className="ml-4 text-lg font-medium leading-6 text-gray-900">Multi answer</p>
              </dt>
            </div>            

            <div className="relative">
              <dt className="flex">
                <svg className='h-6 w-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zm0-160c-53 0-96-43-96-96s43-96 96-96s96 43 96 96s-43 96-96 96z"/></svg>
                <p className="ml-4 text-lg font-medium leading-6 text-gray-900">Single answer</p>
              </dt>
            </div>            

            <div className="relative">
              <dt className='flex'>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>
                <p className="ml-4 text-lg font-medium leading-6 text-gray-900">Ranking</p>
              </dt>
            </div>            

            <div className="relative">
              <dt className="flex">
                <svg className='h-6 w-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zM323.8 202.5c-4.5-6.6-11.9-10.5-19.8-10.5s-15.4 3.9-19.8 10.5l-87 127.6L170.7 297c-4.6-5.7-11.5-9-18.7-9s-14.2 3.3-18.7 9l-64 80c-5.8 7.2-6.9 17.1-2.9 25.4s12.4 13.6 21.6 13.6h96 32H424c8.9 0 17.1-4.9 21.2-12.8s3.6-17.4-1.4-24.7l-120-176zM112 192c26.5 0 48-21.5 48-48s-21.5-48-48-48s-48 21.5-48 48s21.5 48 48 48z"/></svg>
                <p className="ml-4 text-lg font-medium leading-6 text-gray-900">Picture choice</p>
              </dt>
            </div>            

            <div className="relative">
              <dt className="flex">
                <svg className='h-6 w-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M421.7 220.3l-11.3 11.3-22.6 22.6-205 205c-6.6 6.6-14.8 11.5-23.8 14.1L30.8 511c-8.4 2.5-17.5 .2-23.7-6.1S-1.5 489.7 1 481.2L38.7 353.1c2.6-9 7.5-17.2 14.1-23.8l205-205 22.6-22.6 11.3-11.3 33.9 33.9 62.1 62.1 33.9 33.9zM96 353.9l-9.3 9.3c-.9 .9-1.6 2.1-2 3.4l-25.3 86 86-25.3c1.3-.4 2.5-1.1 3.4-2l9.3-9.3H112c-8.8 0-16-7.2-16-16V353.9zM453.3 19.3l39.4 39.4c25 25 25 65.5 0 90.5l-14.5 14.5-22.6 22.6-11.3 11.3-33.9-33.9-62.1-62.1L314.3 67.7l11.3-11.3 22.6-22.6 14.5-14.5c25-25 65.5-25 90.5 0z"/></svg>
                <p className="ml-4 text-lg font-medium leading-6 text-gray-900">Essay</p>
              </dt>
            </div>            

            <div className="relative">
              <dt className='flex'>
                <svg className='h-6 w-6' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z"/></svg>
                <p className="ml-4 text-lg font-medium leading-6 text-gray-900">Exact match</p>
              </dt>
            </div>            

            <div className="relative">
              <dt className='flex'>
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M313.4 32.9c26 5.2 42.9 30.5 37.7 56.5l-2.3 11.4c-5.3 26.7-15.1 52.1-28.8 75.2H464c26.5 0 48 21.5 48 48c0 25.3-19.5 46-44.3 47.9c7.7 8.5 12.3 19.8 12.3 32.1c0 23.4-16.8 42.9-38.9 47.1c4.4 7.2 6.9 15.8 6.9 24.9c0 21.3-13.9 39.4-33.1 45.6c.7 3.3 1.1 6.8 1.1 10.4c0 26.5-21.5 48-48 48H294.5c-19 0-37.5-5.6-53.3-16.1l-38.5-25.7C176 420.4 160 390.4 160 358.3V320 272 247.1c0-29.2 13.3-56.7 36-75l7.4-5.9c26.5-21.2 44.6-51 51.2-84.2l2.3-11.4c5.2-26 30.5-42.9 56.5-37.7zM32 192H96c17.7 0 32 14.3 32 32V448c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32V224c0-17.7 14.3-32 32-32z"/></svg>
                <p className="ml-4 text-lg font-medium leading-6 text-gray-900">True/false</p>
              </dt>
            </div>            

            <div className="relative">
              <dt className="flex">
                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 512c141.4 0 256-114.6 256-256S397.4 0 256 0S0 114.6 0 256S114.6 512 256 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-144c-17.7 0-32-14.3-32-32s14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32z"/></svg>
                <p className="ml-4 text-lg font-medium leading-6 text-gray-900">Instructions</p>
              </dt>
            </div>            
        </dl>
      </div>
    </div>

      {/* CTA */}
      <section className="py-4 bg-yellow-50 sm:py-6 lg:py-8">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="mx-auto text-4xl font-bold text-gray-900 ">
            Get started with a 14-day free trial
          </h2>


            <Link to={'/pricing'} className="mt-4 inline-flex items-center justify-center px-6 py-3 text-base font-bold bg-smcolor text-white rounded-xl focus:outline-none hover:bg-smcolor-dark  hover:text-white " role="button">
              See Plans &amp; Pricing
            </Link>

        </div>
      </div>
    </section>

{/* Customers section */}
<section className="py-12 bg-white sm:py-16 lg:py-20">
      <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="xl:flex xl:items-center xl:justify-between">
          <h2 className="text-xl font-bold text-center text-gray-400 xl:text-left ">
            Trusted by many brands
          </h2>

          <div className="grid items-center grid-cols-1 mt-10 gap-y-6 xl:mt-0 sm:grid-cols-2 sm:gap-y-8 lg:grid-cols-4 lg:gap-x-8">
            <img className="object-contain w-auto mx-auto h-9" src={clientUbi} alt="" />
            <img className="object-contain w-auto mx-auto h-9" src={clientElevate} alt="" />
            <img className="object-contain w-auto mx-auto h-9" src={clientIntertech} alt="" />
            <img className="object-contain w-auto mx-auto h-9" src={clientGtnexus} alt="" />
          </div>
        </div>
      </div>
    </section>

    <Footer showCredits={true}/>

  </>
)
}
 